import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import AppleTouchIcon from '../images/apple-touch-icon-180x180.png'
import ShortcutIcon from '../images/icon-192x192.png'


function SEO({ description, lang, meta, title, image, url, type }) {
  const { site, logo } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
        logo:file(relativePath: {eq: "logo_ogp.png"}) {
          publicURL
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaImage = image || 'https://www.my-ope.net' + logo.publicURL
  const metaUrl = url || '/my-logue/'
  const metaType = type || 'website'
  const metaTitle = title + ' | AI・チャットボットの情報発信メディア'

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: metaImage
        },
        {
          property: `og:type`,
          content: metaType,
        },
        {
          property: `og:url`,
          content: site.siteMetadata.siteUrl + metaUrl
        }
      ].concat(meta)}
      link={[
        { rel: 'shortcut icon', type: 'image/png', href: `${ShortcutIcon}`},
        { rel: 'apple-touch-icon', type: 'image/png', href: `${AppleTouchIcon}`},
      ]}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  url: PropTypes.string
}

export default SEO
